<template>
    <div class="fixed-tab-nav">
        <loader v-if="showLoader" :text="loaderMessage" />

        <div class="st-wizard-tabs pb-6"
            :class="{ fixed: scrollPosition > 10 }">
            <b-nav
                tabs
                justified
                align="start"
                class="st-wizard-tabs-nav"
                v-b-scrollspy="260">
                <b-nav-item key="pre" class="nav-item-initial" disabled>
                    <div class="d-flex justify-content-center icon-arrow-right">
                        <i class="far fa-file-alt"></i>
                    </div>
                </b-nav-item>
                <b-nav-item
                    :href="tab.id"
                    v-on:click.prevent="goToSection(tab.ref, $event)"
                    v-for="tab in tabs"
                    :key="tab.index"
                    :disabled="tab.disabled"
                    v-show="tab.show">
                    <div class="d-flex justify-content-center w-100">
                        <i :class="tab.icon"></i>
                        <span class="nav-item-text text-uppercase ml-4">{{
                            $t(tab.name)
                        }}</span>
                    </div>
                </b-nav-item>
            </b-nav>
            <div class="st-wizard-button-group">
                <st-button
                    variant="light"
                    :disabled="isSigned || toSignByApplicantUser"
                    :spinner="isLoadingSaveDraft"
                    :callback="() => saveAsDraft(true)">
                    <i class="fa fa-save"></i>{{ $t("APPLICATION.FORM.SAVE_AS_DRAFT_BUTTON") }}
                </st-button>
                <st-button
                    :disabled="cannotDownload"
                    variant="primary"
                    :callback="downloadDocumentByCitizen"
                    v-if="showCitizenPreviewBtn">
                    <i class="far fa-file"></i>{{ $t("APPLICATION.FORM.DOWNLOAD_BUTTON") }}
                </st-button>
            </div>
        </div>
        <div class="navbar-scroll">
            <div id="fake-scroll-top" ref="top"></div>
            <application-details-tab
                v-if="!isLoading"
                :applicationId="id"
                ref="applicationDetails"
                @componentEvent="handleEvent"
                @updateSignatureType="updateSignatureType"
                @updateCertSignPackages="updateCertSignPackages"
                @form-mounted="handleFormMounted"
                @app-summary-mounted="handleAppSummaryMounted"
                @updatePaymentType="updatePaymentType"
                @updateDiscountDetails="updateDiscountDetails"
                @isSigned="applicationIsSignedOffline"
                @downloadPdfFile="downloadPdfFile">
            </application-details-tab>

            <div class="d-flex align-items-center justify-content-center mt-6"
                v-if="isDraft">
                <st-button
                    v-if="!isPaid"
                    v-can:edit="'application'"
                    :callback="doPay"
                    :disabled="disablePayment || paymentLoading"
                    :spinner="paymentLoading"
                    >{{ payButtonText }}
                </st-button>
                <st-button
                    v-if="isPaid && !isSigned"
                    v-can:edit="'application'"
                    :callback="doSign"
                    :disabled="signValidatedAndApply"
                    :spinner="signValidatedAndApply"
                    >{{ $t("APPLICATION.FORM.SIGN_BUTTON") }}
                </st-button>
                <st-button
                    v-if="canApply"
                    v-can:edit="'application'"
                    :callback="doApply"
                    >{{ $t("APPLICATION.FORM.APPLY_BUTTON") }}
                </st-button>
            </div>
        </div>
        <confirm-sign-modal
            :applicationId="id"
            ref="confirm-sign-document-modal"
            @signValidated="signValidated"/>
    </div>
</template>
<script>
import { ApplicationsPermissions } from "@/modules/applications/applications-permissions";
import ApplicationDetailsTab from "@/modules/applications/components/view-application/ApplicationDetailsTab";
import { mapActions, mapGetters } from "vuex";
import Message from "@/shared/message/message";
import TaxesStoreObserverMixin from "@/modules/applications/store/taxes-store-observer-mixin";
import ApplicationFormCommonMixin from "@/modules/applications/mixins/application-form-common-mixin";
import { RE_ENROLL_ERRORS } from "@/modules/applications/utils/constants";
import Errors from "@/shared/error/errors";
import Swal from "sweetalert2";
import { BNav, BNavItem, VBScrollspy } from "bootstrap-vue";
import { axiosInlineDownload } from "@/modules/applications/utils/axios-inline-downloader";

export default {
    name: "ApplicationEdit",
    mixins: [TaxesStoreObserverMixin, ApplicationFormCommonMixin],
    components: {
        BNav,
        BNavItem,
        ApplicationDetailsTab,
        ConfirmSignModal: () => import("@/modules/applications/components/signature/ConfirmSignModal"),
    },
    directives: {
        "b-scrollspy": VBScrollspy,
    },
    props: {
        id: String,
    },
    watch: {
        signValidatedAndApply(newValue, oldValue) {
            if (!newValue || newValue === oldValue) {
                Swal.close();
                return false;
            }
            this.handleOpenLoader();
        },
    },
    data() {
        return {
            paymentStatus: "successful",
            signDetails: {
                isOnline: true,
                package: null,
            },
            identificationFilesUploaded: false,
            scrollPosition: null,
            needPackageValidation: true,
            signingLoading: true,
            isPaymentPending: false,
            isUploadingPending: false,
            paymentDetails: {
                payment_type: "CARD",
            },
        };
    },
    computed: {
        ...mapGetters({
            record: "applications/form/record",
            loading: "shared/loading",
            currentUser: "auth/currentUser",
            uploadAccessToken: "applications/form/uploadAccessToken",
            downloadToken: "applications/form/fileToken",
            signingStatus: "applications/form/signingStatus",
            totalTaxes: "applications/form/totalTaxes",
            tenantId: "applications/form/tenantId",
            availableUatIds: "applications/form/availableUatIds",
        }),
        hasPermissionToReadStaff() {
            return new ApplicationsPermissions(this.currentUser).readStaff;
        },
        isLoading() {
            return this.loading["applications/find"];
        },
        isLoadingSaveDraft() {
            return (
                this.loading["applications/create"] ||
                this.loading["applications/update"] ||
                this.isUploadingPending
            );
        },
        isLoadingSign() {
            return this.loading["applications/signDocumentCitizen"];
        },
        paymentLoading() {
            return (
                this.isUploadingPending ||
                this.loading["applications/pay"] ||
                this.isPaymentPending ||
                (this.paymentStatus !== "successful" &&
                    this.paymentStatus !== "failed")
            );
        },
        tabs() {
            return [
                {
                    index: 0,
                    name: "APPLICATION.TABS.NEW_APPLICATION",
                    ref: "top",
                    id: "#fake-scroll-top",
                    icon: "fas fa-edit",
                    show: true,
                },
                {
                    index: 1,
                    name: "APPLICATION.TABS.BENEFICIARY",
                    ref: "beneficiaryList",
                    id: "#beneficiary-target",
                    icon: "fas fa-users",
                    show: true,
                },
                {
                    index: 2,
                    name: "APPLICATION.TABS.REAL_ESTATE",
                    ref: "realEstateList",
                    id: "#real-estate-target",
                    icon: "fas fa-home",
                    show: true,
                },
                {
                    index: 3,
                    name: "APPLICATION.TABS.SIGN",
                    ref: "signatureDetails",
                    id: "#signature-details-target",
                    icon: "fas fa-pencil-alt",
                    show: true,
                },
                {
                    index: 4,
                    name: "APPLICATION.TABS.SUMMARY",
                    ref: "applicationSummary",
                    id: "#summary",
                    icon: "fas fa-check-circle",
                    show: true,
                },
            ];
        },
        isLoadingSave() {
            return this.loading["applications/update"];
        },
        viewMode() {
            return !this.isDraft;
        },
        isDraft() {
            return this.record?.status === "draft";
        },
        isPaid() {
            return this.record?.is_paid;
        },
        isSigned() {
            return this.record?.is_signed;
        },
        toSignByApplicantUser() {
            return this.record?.to_sign_by_applicant_user;
        },
        paymentSuccessful() {
            return this.paymentStatus === "successful";
        },
        signValidatedAndApply() {
            return (
                this.loading["applications/signValidatedAndApply"] ||
                this.loading["applications/finalize"]
            );
        },
        showLoader() {
            return (
                this.paymentLoading ||
                this.loading["applications/initializeOfflineSign"] ||
                this.isLoading ||
                this.isLoadingSign ||
                this.isLoadingSaveDraft ||
                this.signingLoading ||
                this.loading["applications/previewDocumentByCitizen"] ||
                this.loading["applications/getApplicationPaymentStatus"]
            );
        },
        showCitizenPreviewBtn() {
            return true;
        },
        canApply() {
            return this.isPaid && this.isSigned && this.isDraft;
        },
        loaderMessage() {
            return this.isUploadingPending ? this.$t('APPLICATION.NOTIFIERS.UPLOADING_FILES') : this.offlineSigningText;
        },
        cannotDownload() {
            if (this.isPaid || this.record?.offlineSign?.files?.length > 0) {
                return true;
            }

            return false;
        },
        payButtonText() {
            if (this.totalTaxes === 0) {
                return this.$t("APPLICATION.FORM.CONTINUE_BUTTON");
            }
            return this.$t("APPLICATION.FORM.PAY_BUTTON");
        }
    },
    methods: {
        ...mapActions({
            doFind: "applications/form/find",
            doClear: "applications/form/clear",
            doUpdate: "applications/form/update",
            doUpload: "applications/form/upload",
            doPayApplication: "applications/form/pay",
            initializeOfflineSigning: "applications/form/initializeOfflineSigning",
            sendApplication: "applications/form/finalizeApplication",
            doEnrollment: "applications/form/enrollPerson",
            signDoc: "applications/form/signDocumentCitizen",
            checkSigningStatus: "applications/form/checkSigningStatus",
            updateOfflineSignDownloadStatus: "applications/form/updateOfflineSignDownloadStatus",
            doDownloadDocumentByCitizen: "applications/form/previewDocumentByCitizen",
            inactivateLoading: "shared/setInactiveLoading",
            initApplicationState: "applications/form/initFormState",
            updateApplicationValidation: "applications/form/validation",
            checkPaymentStatus: "applications/form/doFindApplicationPaymentStatus",
            doSetDiscountDetails: "applications/form/doSetDiscountDetails",
        }),
        navItemClass(tab) {
            if (tab?.disabled) return "";
            if (this.id) return "nav-item--success";
            return "nav-item--warning";
        },
        goToPage(tab) {
            if (tab?.link) {
                this.$router.push({
                    name: tab.link,
                });
            }
        },
        async generateData(asDraft) {
            try {
                const mainRef = this.$refs.applicationDetails.$refs;
                const genFormData = await mainRef.generateForm.getData(asDraft);

                const { options, shared_fields, form } = genFormData;
                let formLables = {};

                for (let item in options) {
                    const opts = options[item].options;
                    if (opts?.length && typeof opts[0] === "object") {
                        const selectedOption = opts.find(
                            (opt) => opt.value === form[item]
                        );
                        if (selectedOption) {
                            formLables[`${item}_label`] = selectedOption.label;
                        }
                    }
                }

                const formWithOptions = {
                    form: {
                        ...formLables,
                        ...form,
                        options,
                        shared_fields
                    },
                };

                const formData = {
                    ...formWithOptions,
                };

                return formData;
            } catch (error) {
                this.updateApplicationValidation(false);
            }

        },
        async clearData(data) {
            const mainRef = this.$refs.applicationDetails.$refs;
            const appDetailsRef = this.$refs.applicationDetails;
            await appDetailsRef.clearFormData(data);
            await mainRef.generateForm.clearGenerateFormData(data);
        },
        async saveAsDraft(asDraft, redirect) {
            const data = await this.generateData(asDraft);
            if (!data) return false;

            data.status = "draft";
            data.sign_details = this.signDetails;
            data.payment_details = this.paymentDetails;
            data.discount_details = this.discountDetails ?? {};

            const fileDataObj = this.getFileData(data.form);
            const {fileItems} = fileDataObj;
            const fileData = fileItems;

            let uploadedFiles = [];
            let uploadedIdentificationFiles = [];
            let existingFiles = [];

            if (fileData && this.uploadAccessToken) {
                this.isUploadingPending = true;
                const promises = [];
                for (let i = 0; i < Object.keys(fileData).length; i++) {
                    const inputFileName = Object.keys(fileData)[i];
                    if (inputFileName !== "identification_files") {
                        const files = fileData[inputFileName];
                        const currentUploadedFiles = await this.handleUploadFiles({
                            files,
                            uploadAccessToken: this.uploadAccessToken,
                        });

                        promises.push(currentUploadedFiles);
                        const filesFormatted = currentUploadedFiles.map(
                            (el) => ({
                                file_id: el.id,
                                filename: el.initialFilename,
                                created_date: el.created_date,
                                file_type: "input",
                                created_by: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
                            })
                        );

                        uploadedIdentificationFiles = [
                            ...uploadedIdentificationFiles,
                            ...filesFormatted
                        ]
                        let matchedFiles = [];
                        if (this.record?.form?.[inputFileName]?.length) {
                            matchedFiles = this.record.form[inputFileName].filter((el) => !(el instanceof File));
                        }
                        uploadedFiles[inputFileName] = [
                            ...matchedFiles,
                            ...filesFormatted,
                        ];
                    } else {
                        existingFiles = fileData["identification_files"];
                    }
                }

                Promise.all(promises).finally(()=> {
                    this.isUploadingPending = false;
                });
                this.identificationFilesUploaded = true;
            }

            const attachments = [
                ...existingFiles,
                ...uploadedIdentificationFiles
            ];

            if(!this.record?.gis_files?.length) {
                data.gis_files_layers = [];
            }

            const payload = {
                id: this.record.id,
                values: {
                    ...data,
                    identification_files: attachments,
                    form: {
                         ...data.form,
                         ...uploadedFiles,
                         attachments: data?.form?.attachments && data.form.attachments.length ? data.form.attachments: attachments
                    },
                },
            };

            const application = await this.doUpdate(payload);
            await this.clearData(this.record);
            if (asDraft) {
                Message.success("APPLICATION.NOTIFIERS.DRAFT_SAVED_SUCCESS");
            }

            if (redirect) {
                this.$router.push({
                    name: "applications",
                });
            } else {
                return application;
            }
        },
        async handleUploadFiles({files, uploadAccessToken}) {
            try {
                return await this.doUpload({
                    files,
                    uploadAccessToken
                });
            }
            catch (e) {
                this.isUploadingPending = false;
                this.isPaymentPending = false;
                throw e;
            }
        },
        async doPay() {

            if (this.record.unauthorized_payment) {
                this.$alert({
                    type: 'warning',
                    text: this.$t('APPLICATION.NOTIFIERS.UNAUTHORIZED_PAYMENT'),
                    showConfirmButton: false,
                });

                return;
            }

            //need to check if the user has enough signatures
            if (this.signDetails.isOnline && this.needPackageValidation && !this.signDetails.package) {
                Message.error("APPLICATION.NOTIFIERS.CHOOSE_SIGN_PACKAGE");
                return;
            }

            if (!this.signDetails.isOnline) {
                //check if signed, if it's offline option selected
                if (this.record && !this.record.is_signed) {
                    Message.error("APPLICATION.NOTIFIERS.OFFLINE_SIGN_FAILED");
                    return;
                }
            }

            //update application
            const application = await this.saveAsDraft(false);

            if (!application) {
                return false;
            }

            const payload = {
                id: application.id,
                payment_initiated: application?.payment_initiated ?? false,
                data: {
                    amount: 1,
                    currency: this.$t("GENERAL.CURRENCY_LABEL"),
                    summary: [],
                },
            };

            if (this.signDetails.isOnline && this.signDetails.package) {
                payload.data.summary.push({
                    id: this.signDetails.package.id,
                    name: this.signDetails.package.name,
                    type: "CERTSIGN",
                    amount: this.signDetails.package.price_including_vat ?? 0,
                    signatures: this.signDetails.package.quantity ?? 0,
                });
            }
            this.isPaymentPending = true;
            this.doPayApplication(payload)
                .then(async (data) => {
                    if (
                        this.paymentDetails.payment_type === "OP" &&
                        data?.op_payment_id
                    ) {
                        this.$router.push({
                            name: "applicationsPaymentOrder",
                            params: { opPaymentId: data?.op_payment_id, applicationId: application.id },
                        });
                    } else if (data?.bt_payment_form_url) {
                        window.location.replace(data.bt_payment_form_url);
                    } else {
                        await this.doFind({ id: this.record.id, loadStaff: false });
                        this.$router.push({ name: 'applicationEdit', params: { id: this.record.id }, query: { state: 'redirect' } });
                        this.isPaymentPending = false;
                    }
                })
                .catch(() => {
                    this.isPaymentPending = false;
                });
        },
        handleEvent(callback) {
            if (this[callback]) {
                this[callback]();
            }
        },
        async doSign() {

            let enrollmentRequest = {
                user_id: this.currentUser.user_id,
                email: this.currentUser.email,
                first_name: this.currentUser.first_name,
                last_name: this.currentUser.last_name,
                id_card_number: this.currentUser.id_card_number,
                id_card_serial: this.currentUser.id_card_serial,
                id_card_expiration_date: this.currentUser.id_card_expiration_date,
                id_card_issuer: this.currentUser.id_card_issuer,
                cnp: this.currentUser.personal_numeric_code,
                phone_number: this.currentUser.phone_number,
                county: this.currentUser.county,
                type: 0,
            };

            const showModalInnerMethod = () => {
                this.showConfirmSignModal = true;
                this.$nextTick(() => {
                    this.$refs["confirm-sign-document-modal"].show();
                });
            };

            if (this.currentUser.enrolled) {
                try {
                    await this.signDoc({ id: this.record.id });
                    showModalInnerMethod();
                } catch (err) {
                    if (RE_ENROLL_ERRORS.includes(err)) {
                        await this.doEnrollment({
                            ...enrollmentRequest,
                            force: true,
                        });
                        await this.signDoc({ id: this.record.id });
                        showModalInnerMethod();
                        return;
                    }

                    Errors.handle(err);
                }

                return;
            }

            await this.doEnrollment(enrollmentRequest);
            await this.signDoc({ id: this.record.id });
            showModalInnerMethod();
        },
        doApply() {
            this.sendApplication(this.id)
                .then((data) => {
                    this.$router.push({
                        name: "applicationConfirmation",
                        params: {
                            id: data.identification_number,
                        },
                    });
                })
                .catch(async () => {
                    Message.error('APPLICATION.NOTIFIERS.SENDING_ERROR')
                    this.inactivateLoading("applications/signValidatedAndApply");
                    //should fix sign and send button if the application is signed and there is a timeout
                    await this.doFindRecord();
                });
        },
        signValidated() {
            this.doApply();
        },
        goToSection(href, e) {
            if (e) e.stopPropagation();
            let $el;
            if (href === "top") {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            } else if (this.$refs.applicationDetails.$refs[href]) {
                $el = this.$refs.applicationDetails.$refs[href].$el;
            } else if (
                this.$refs.applicationDetails.$refs.generateForm.$refs[href]
            ) {
                $el = this.$refs.applicationDetails.$refs.generateForm.$refs[href][0].$el;
            }
            if ($el) {
                const elementPosition = $el.offsetTop;
                window.scrollTo({
                    top: elementPosition,
                    behavior: "smooth",
                });
            }
        },
        updateScroll() {
            this.scrollPosition = window.scrollY;
        },
        updateSignatureType(isOnline) {
            this.signDetails = {
                isOnline,
            };
        },
        updateCertSignPackages(item) {
            this.signDetails = {
                ...this.signDetails,
                package: item,
            };
        },
        validatePackage(need) {
            this.needPackageValidation = need;
        },
        updatePaymentType(paymentType) {
            this.paymentDetails = {
                payment_type: paymentType,
            };
        },
        updateDiscountDetails(discountDetails) {
            this.discountDetails = discountDetails;
            this.doSetDiscountDetails(discountDetails);
        },
        handleFormMounted() {
            this.$nextTick(() => {
                // Redirected from payment then scroll to summary section after all components are rendered
                if (
                    this.$route.query &&
                    this.$route.query.state &&
                    this.$route.query.state === "redirect"
                ) {
                    const el = document.querySelector("#summary");
                    el && el.scrollIntoView();
                }
            });
        },
        handleAppSummaryMounted() {
            this.$nextTick(() => {
                // Redirected from view mode then scroll to summary section after the summary section is mounted
                if (this.$route.params.tabRef) {
                    setTimeout(() => {
                        this.goToSection(this.$route.params.tabRef);
                    }, 500);
                }
            });
        },
        async doFindRecord() {
            await this.doFind({
                id: this.id,
                loadStaff: this.hasPermissionToReadStaff,
            });
        },
        async checkSignConfirmation(id) {
            try {
                await this.checkSigningStatus(id);
                if (this.signingStatus !== "pending") {
                    clearInterval(this.polling);
                    await this.doFindRecord();
                    this.signingLoading = false;
                }
            } catch (error) {
                clearInterval(this.polling);
            }
        },
        clearPollingScraps() {
            if (this.polling) {
                clearInterval(this.polling);
            }
        },
        handleOpenLoader() {
            Swal.fire({
                icon: "info",
                iconHtml: `<i class="fas fa-info-circle"></i>`,
                title: this.$t("APPLICATION.SIGN_AND_APPLY_LOADER.TITLE"),
                text: this.$t("APPLICATION.SIGN_AND_APPLY_LOADER.TEXT"),
                allowOutsideClick: false,
                allowEscapeKey: false,
                customClass: {
                    container: "st-alert-container",
                    actions: "apply-loader-container",
                },
                didOpen: () => {
                    Swal.showLoading();
                },
            });
        },
        async applicationIsSignedOffline() {
            await this.saveAsDraft(false);

            if (!this.identificationFilesUploaded) {
                await this.doFind({ id: this.record.id, loadStaff: false });
            }
        },
        async downloadPdfFile() {
            //save application
            const application = await this.saveAsDraft(false);
            if (!application) return false;

            const initializeSign = await this.initializeOfflineSigning({
                id: this.id,
                filesLocation: "files",
            });

            if (initializeSign?.offlineSign?.files) {
                this.updateOfflineSignDownloadStatus(true);
                const lastFile = initializeSign.offlineSign.files[initializeSign.offlineSign.files.length - 1];
                await axiosInlineDownload({
                    url: `${window.VUE_APP_API_URL}/files/${lastFile.file_id}?token=${this.downloadToken}`,
                    label: lastFile.filename,
                    type: 'application/pdf',
                });
            }
        },
        async downloadDocumentByCitizen() {
            let response = await this.saveAsDraft(false);

            if (!response) {
                return false;
            }

            const { data, downloadAccessToken } = await this.doDownloadDocumentByCitizen({ id: this.record.id });

            window.open(`${window.VUE_APP_API_URL}/files/${data.file_id}?token=${downloadAccessToken}`, "_blank");
        },
    },
    mounted() {
        window.addEventListener("scroll", this.updateScroll);
    },
    async created() {
        if (this.$route.query.state === "redirect" || (this.id && !this.record)) {
            await this.doFindRecord();
        }

        if (this.id) {
            if (this.record.payment_initiated_public && !this.isPaid) {
                if (
                    this.record.payment_details.payment_type === "CARD" ||
                    this.record.payment_type === "CARD"
                ) {
                    const res = await this.checkPaymentStatus(this.id);
                    if (res.status === 'valid') {
                        window.location.replace(this.record.bt_payment_form_url);
                    }
                } else {
                    this.$router.replace({
                        name: "applicationsPaymentOrder",
                        params: { opPaymentId: this.record?.op_payment_id, applicationId: this.id },
                    });
                }
            }

            await this.checkSigningStatus(this.id);
            if (this.signingStatus === "pending") {
                this.polling = setInterval(() => {
                    this.checkSignConfirmation(this.id);
                }, 5000); // TO DO: Decrease interval to 1000
            } else {
                this.signingLoading = false;
            }
        }

        if (!this.id) {
            await this.doClear();
        }

        if (this.record?.sign_details) {
            this.signDetails = this.record.sign_details;
        }

        if (this.record?.payment_details?.payment_type) {
            this.paymentDetails = this.record.payment_details;
        }

        if (this.record?.discount_details?.id) {
            this.discountDetails = this.record.discount_details;
        }

        if (this.record?.offlineSign?.files)
            this.updateOfflineSignDownloadStatus(true);
    },
    beforeDestroy() {
        this.initApplicationState();
        this.inactivateLoading("applications/signValidatedAndApply");
        Swal.close();
        this.clearPollingScraps();
    },
    destroy() {
        this.isPaymentPending = false;
        window.removeEventListener("scroll", this.updateScroll);
    },
};
</script>
<style scoped>
@import "~quill/dist/quill.snow.css";

/* Used to fake the scroll to select the first breadcrumb navbar item on pageload
and scroll to the first item because we don't have the detalis section */
#fake-scroll-top {
    margin-top: -1px;
    height: 1px;
}
</style>
